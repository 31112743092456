import { Box, Link, Text } from "@chakra-ui/react";
import React from "react";
import { Balancer } from "react-wrap-balancer";

import { PostMeta } from "../renderer/types";
import { Date } from "./Date";
import { useColors } from "./useColors";

export const HomePost = ({ meta }: { meta: PostMeta }) => {
  const { url, title, date, subtitle } = meta;
  const { linkBackground, subtitleColor } = useColors();

  return (
    <Box>
      <Link
        href={url}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        h="full"
        p={4}
        _hover={{
          textDecoration: "none",
          bg: linkBackground,
        }}
        _focus={{ bg: linkBackground }}
      >
        <Text
          fontSize="sm"
          fontFamily="sans"
          fontWeight="medium"
          color={subtitleColor}
        >
          <Date date={date} />
        </Text>
        <Text fontWeight="bold">
          <Balancer>{title}</Balancer>
        </Text>
        <Text fontStyle="italic">
          <Balancer>{subtitle}</Balancer>
        </Text>
      </Link>
    </Box>
  );
};
