import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { Balancer } from "react-wrap-balancer";

import { HomePost } from "#components/HomePost";
import { Link } from "#components/Link";
import { SUBSCRIBE_URL, TITLE } from "../renderer/constants";
import { postMeta } from "./postMeta";

const Page = () => {
  return (
    <Box mx="auto" mt={10} mb={40} w="full" maxW="40em" py={4}>
      <Text mb={8} fontSize="5xl" fontStyle="italic" fontWeight="thin" px={4}>
        {TITLE}.
      </Text>
      <Text my="parSpace" px={4}>
        <Balancer>
          Writing and blog posts by{" "}
          <Link href="https://cjquines.com/">CJ Quines,</Link> about life, math,
          design, code, whatever.
        </Balancer>
      </Text>
      <Text my="parSpace" px={4}>
        Subscribe <Link href="/atom.xml">via RSS.</Link> Subscribe{" "}
        <Link href={SUBSCRIBE_URL}>via email.</Link>
      </Text>
      <Box my="parSpace">
        {postMeta.map((meta) => (
          <HomePost key={meta.url} meta={meta} />
        ))}
      </Box>
    </Box>
  );
};

export default Page;
